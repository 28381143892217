import React from "react";
import { Row, Container, Breadcrumb, Card } from "react-bootstrap";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { ForgotPassword, isUserSignedIn } from "../../components/accountManagement";

const uri = "/account/forgot-password/";

const ForgotPasswordPage = ({ location }) => {
  const userData = useSelector((state) => state.userData);

  return (
    <Layout pageInfo={{ pageName: "forgotpassword" }}>
      <Seo
        title="Forgot Password"
        keywords={[`forgot password`, `login`]}
        description="Forgot Password page"
        url={uri}
      />
      <Container fluid>
        <Row className="justify-content-left my-3">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/account/">Account</Breadcrumb.Item>
            <Breadcrumb.Item active>Forgot Password</Breadcrumb.Item>
          </Breadcrumb>
        </Row>

        {isUserSignedIn(userData) ? (
          <>User is signed in {navigate("/")}</>
        ) : (
          <Row className="d-flex flex-wrap">
            <Card className="account-mgmt-card">
              <Card.Body>
                <ForgotPassword
                  onForgotPassword={() => navigate("/")}
                  signInClick={() => navigate("/account/signin/")}
                  showLogo={false}
                />
              </Card.Body>
            </Card>
          </Row>
        )}
      </Container>
    </Layout>
  );
};

export default ForgotPasswordPage;
